<template>
  <!-- 搜索页 -->
  <div class="main-wrapper">
    <!-- logo -->
    <img class="logo" src="../assets/images/search/logo.png" />
    <div class="search-content">
      <!-- 搜索输入框 -->
      <el-input
        ref="searchInputRef"
        v-model.trim="state.keywords"
        size="large"
        placeholder="请输入内容"
        @focus="searchEntityByKeywords"
        @keyup="searchEntityByKeywords"
        @keyup.enter="searchByKeywords()"
      >
        <template #append>
          <el-button icon="Search" @click="searchByKeywords()" />
        </template>
      </el-input>

      <!-- 搜索推荐列表 -->
      <el-card class="box-card" v-if="state.cardVisible">
        <el-scrollbar :height="scrollbarHeight()">
          <div
            class="search-item"
            v-for="(entityItem, entityItemKey) in state.searchCardList"
            :key="entityItemKey"
            @click="selectEntity(entityItem.name, entityItem.entityId)"
          >
            <div class="title">
              <h3>{{ entityItem.name }}</h3>
              <span>{{ entityItem.type }}</span>
            </div>
            <p class="description kn-ellipsis">
              {{ entityItem.description }}
            </p>
          </div>
          <!-- 提示文字 -->
          <div v-if="state.searchCardList.length == 0" class="notice">
            没有匹配到对应实体
          </div>
        </el-scrollbar>
      </el-card>
    </div>
  </div>
</template>

<script setup>
import { httpPost } from "@/api/httpService";
import router from "@/router";
import { useRoute } from "vue-router";
import { ref, reactive, getCurrentInstance, inject, nextTick } from "vue";
import CreateEntityDrawer from "@/components/graph/CreateEntityDrawer";
import { remindErrorMessage } from "@/utils/utils";
const { proxy } = getCurrentInstance();
const route = useRoute();
const searchInputRef = ref(null);
const handleOpenDrawer = inject("handleOpenDrawer");

const state = reactive({
  cardVisible: false, //是否展示搜索推荐卡片
  keywords: "", // 搜索关键字
  entityId: "", // 搜索实体的id
  searchCardList: [], //推荐卡片数据
});

/**根据关键词查询推荐实体列表 */
const searchEntityByKeywords = async () => {
  if (!state.keywords) {
    state.cardVisible = false;
    return;
  } else {
    let knowledgeGraphId = window.localStorage.getItem(
      "knowledge-selectedGraph"
    );
    if (knowledgeGraphId == null) {
      remindErrorMessage("请选择图谱库");
      return;
    }
    state.cardVisible = true;
    nextTick(async () => {
      state.searchCardList = [];
      let url = "/graph/v1/searchEntityByKeywords";
      let params = {
        knowledgeGraphId: knowledgeGraphId,
        keywords: state.keywords,
      };
      let res = await httpPost(url, params);
      if (res.code == 0) {
        state.searchCardList = res.data;
      }
    });

    // console.log(state.searchCardList);
  }
};
/* 展示添加实体抽屉 */
const handleOpenAddEntityDrawer = () => {
  // console.log(proxy);
  // proxy.$refs["addEntityDrawer"].openDrawer();
  handleOpenDrawer();
};

/* 选中实体 */
const selectEntity = (name, entityId) => {
  state.entityId = entityId;
  state.keywords = name;
  searchInputRef.value.focus();
  state.cardVisible = false;
  router.push({
    path: "./graph",
    query: { entityId: state.entityId, keywords: name },
  });
};

/* 查询实体 */
const searchByKeywords = async () => {
  let knowledgeGraphId = window.localStorage.getItem("knowledge-selectedGraph");
  if (knowledgeGraphId == null) {
    remindErrorMessage("请选择图谱库");
    return;
  }
  state.cardVisible = false;
  let url = "/graph/v1/searchEntityByKeywords";
  let params = {
    knowledgeGraphId: knowledgeGraphId,
    keywords: state.keywords,
  };
  let res = await httpPost(url, params);
  if (res.code == 0) {
    state.searchCardList = res.data;
  }
  // console.log("222", state.searchCardList);
  if (state.searchCardList.length !== 0) {
    if (state.searchCardList[0].name === state.keywords) {
      state.entityId = state.searchCardList[0].entityId;
      router.push({
        path: "./graph",
        query: {
          entityId: state.entityId,
          keywords: state.searchCardList[0].name,
        },
      });
    } else {
      remindErrorMessage("暂无该实体");
    }
  } else {
    remindErrorMessage("暂无该实体");
  }
};

// 滚动条的显示
const scrollbarHeight = () => {
  if (state.searchCardList.length > 5) {
    return "350px";
  } else {
    nextTick(() => {
      document.querySelector(".el-scrollbar").removeAttribute("height");
    });
  }
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);

  // logo
  img.logo {
    height: 40px;
  }
  // 搜索框主体
  .search-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    // 搜索框
    .el-input {
      width: 600px;
      margin-top: 50px;

      :deep(.el-input-group__append) {
        background-color: #fff;
        color: #777;
      }

      :deep(.el-input__inner) {
        text-align: center;
      }

      :deep(.el-input__wrapper) {
        height: 48px;
        box-shadow: 0 0 0 0px
          var(--el-input-border-color, var(--el-border-color)) inset;
        border: var(--el-input-border-color, var(--el-border-color)) 1px solid;
        border-right: none;
      }

      :deep(.el-input-group__append) {
        box-shadow: 0 0 0 0px
          var(--el-input-border-color, var(--el-border-color)) inset;
        border: var(--el-input-border-color, var(--el-border-color)) 1px solid;
        border-left: none;
      }
    }

    // 搜索推荐
    .el-card {
      width: 600px;
      padding-top: 13px;
      padding-bottom: 0;
      margin-top: -1px;

      :deep(.el-card__body) {
        padding: 0;
      }

      .search-item {
        display: flex;
        flex-direction: column;
        height: 70px;
        padding: 5px;
        margin: 0 13px;
        cursor: pointer;

        &:hover {
          background-color: #f4f9fd;
        }

        .title {
          flex: 1;
          display: flex;
          justify-content: space-between;

          h3 {
            color: #101010;
            font-size: 16px;
            line-height: 30px;
            font-weight: 400;
          }

          span {
            color: #101010;
            font-size: 14px;
            line-height: 30px;
          }
        }

        .description {
          width: 260px;
          flex: 1;
          line-height: 30px;
          align-self: flex-start;
          color: #777777;
          font-size: 12px;
          text-align: left;
        }
      }

      .notice {
        height: 70px;
        color: #777777;
        font-size: 16px;
        line-height: 70px;

        a {
          color: #3894ff;
        }
      }
    }
  }
}
</style>
